// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanmelden-js": () => import("./../../../src/pages/aanmelden.js" /* webpackChunkName: "component---src-pages-aanmelden-js" */),
  "component---src-pages-aanmeldenvlinder-js": () => import("./../../../src/pages/aanmeldenvlinder.js" /* webpackChunkName: "component---src-pages-aanmeldenvlinder-js" */),
  "component---src-templates-activiteiten-js": () => import("./../../../src/templates/Activiteiten.js" /* webpackChunkName: "component---src-templates-activiteiten-js" */),
  "component---src-templates-tekstpagina-js": () => import("./../../../src/templates/Tekstpagina.js" /* webpackChunkName: "component---src-templates-tekstpagina-js" */)
}

